import instance from "../../utils/axios";
import { useState, useEffect } from "react";
import { Audio } from "react-loader-spinner";
import API_URL from "../../utils/urls";

const ChoseEmailSend = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [finalSend, setFinalSend] = useState(false);
  const [finalSendingData, setFinalSendingData] = useState(null);
  const [list, setList] = useState([]);
  const [listPhotos, setListPhotos] = useState([]);
  const [withPhoto,setWithPhoto] = useState(null)
  const [choosePhoto,setChoosePhoto] = useState(null)
  const [startSend, setStartSend] = useState(null);
  const [startSending, setStartSending] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [finalStart, setFinalStart] = useState(false);

    // Функція для обробки змін стану чекбокса
    const handleCheckboxChange = (event) => {
      setWithPhoto(event.target.checked);  // Оновлюємо стан в залежності від того, чи вибрано чекбокс
      if (!withPhoto) {
        getListOfPhotos()
      }else {
        setListPhotos([])
      }
    };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getListOfMails = async () => {
    try {
      const data = await instance.get("/xls-files");

      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getListOfPhotos = async () => {
    try {
      const data = await instance.get("/image-files");

      setListPhotos(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOneList = async (item) => {
    try {
      const data = await instance.post("/delete-file", { filename: item });

      if (data.status === 200) {
        setDeleted(true);
        getListOfMails();
    
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteOnePhoto = async (item) => {
    try {
      const data = await instance.post("/delete-file", { filename: item });

      if (data.status === 200) {
        setDeleted(true);
        getListOfPhotos();
      
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getListOfMails();
  }, [deleted]);
  // useEffect(() => {
  //   getListOfPhotos();
  // }, [deleted]);

  const handleStartSending = async () => {
    const obj = {
      type: selectedOption,
      commentTitle: title,
      commentText: text,
      documentTitle: startSend,
      photo:choosePhoto ? choosePhoto : null
    };

    try {
      if (!selectedOption || !title || !text) {
        alert("Заповність усі поля");
      } else {
        setIsLoading(true);
        const data = await instance.post("/email/create-list", obj);

        setFinalSendingData(data.data);

        if (data.status === 200) {
          setStartSending(true);
          setIsLoading(false);
          setFinalSend(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleStartDirectSend = async () => {
    try {
      const obj = {
        title,
        text,
        table: finalSendingData?.data,
        photo:choosePhoto? choosePhoto : null
      };

      setFinalStart(true);
      const data = await instance.post("/email/direct-send", obj);
    } catch (error) {
      console.log(error);
    }
  };

console.log('WITH PHOTO',choosePhoto);

  return (
    <>
      <div>Список розсилок</div>
      <div className="greeting__list">
        {list &&
          list.map((item, idx) => {
            return (
              <div
                className={`greeting__list-file ${
                  startSend === item ? "active" : ""
                }`}
                key={idx}
              >
                <span>{item}</span>
                <button onClick={() => setStartSend(item)}>Обрати</button>
                <button onClick={() => deleteOnePhoto(item)}>Видалити</button>
              </div>
            );
          })}

        {finalSend
          ? null
          : startSend && (
              <div className="start_sending">
                <h2>Налаштування розсилки</h2>

                {listPhotos && (
                  <div className="list_photos" style={{display:"flex",gap:"10px",width:"100%"}}>
                    {listPhotos &&
                      listPhotos.map((item, idx) => {
                        return (
                          <div
                          style={{display:"flex",flexDirection:"column"}}
                            className={`greeting__list-file ${
                              choosePhoto === item ? "active" : ""
                            }`}
                            key={idx}
                          >
                            <img
                              style={{ width: "200px" }}
                              src={`${API_URL}/uploads/images/${item}`}
                              alt="item"
                            />
                       <div className="" style={{display:"flex",justifyContent:"space-between"}}>
                       <div style={{backgroundColor:"green",width:"100%",cursor:"pointer"}} onClick={() => setChoosePhoto(item)}>
                              Обрати
                            </div>


                            <div style={{backgroundColor:"red",width:"100%",cursor:"pointer"}} onClick={() => deleteOneList(item)}>
                              Видалити
                            </div>
                       </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                <form>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="per"
                        name="type"
                        checked={selectedOption === "per"}
                        onChange={handleChange}
                      />
                      Перевізник
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="zam"
                        name="type"
                        checked={selectedOption === "zam"}
                        onChange={handleChange}
                      />
                      Замовник
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="user"
                        name="type"
                        checked={selectedOption === "user"}
                        onChange={handleChange}
                      />
                      Користувачам
                    </label>
                    <hr />
                    <div>
      <label>
        <input 
          type="checkbox" 
          checked={withPhoto}  // Чекбокс буде позначений, якщо withPhoto = true
          onChange={handleCheckboxChange}  // Оновлюємо стан при зміні
        />
          { withPhoto ? "З фото (оберіть)" : "Без фото" }
      </label>


    
    </div>
                  </div>
                </form>
                <div className="start_sending-form">
                  <input
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    placeholder="Тема"
                  />
                  <textarea
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    rows={10}
                    placeholder="Напишіть текст для розсилки"
                  />
                  <button
                    disabled={isLoading ? true : false}
                    onClick={handleStartSending}
                  >
                    Зробити список розсилки
                  </button>
                </div>
              </div>
            )}

        {isLoading && (
          <>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="info"
            >
              <Audio
                height="80"
                width="80"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          </>
        )}

        {startSending && (
          <div className="final_sending">
            <p>
              Листів до відправлення: {finalSendingData?.tableResult?.rowCount}
            </p>
            {finalStart ? null : (
              <button
                disabled={finalStart ? true : false}
                onClick={handleStartDirectSend}
                style={{
                  fontSize: "24px",
                  color: "green",
                  backgroundColor: "lightgrey",
                }}
              >
                {finalStart ? "Розсилка запущена" : "Розпочати розсилку"}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ChoseEmailSend;
