import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from '../../../utils/axios'
const PrinterLocationEditModal = ({open,setOpen,item,refreshFunc,startData}) => {

    
const [editPrinterId,setEditPrinterId] = useState(item?.printer_id)
const [editLocation,setEditLocation] = useState(item?.serial_number)




const updatePrinterLocation = async ()=>{
    const obj = {
        printer_id:editPrinterId,
        location_id:editLocation,
      
    }
    try {
        const data = await axios.post('/printers/update-location-change',obj);
    
        if (data.status === 200) {
            setOpen(false);
            refreshFunc()

        }
        
    } catch (error) {
        console.log(error);
        
    }
}





useEffect(()=>{
    setEditPrinterId(item?.printer_id)
    setEditLocation(item?.location_id)
},[item])
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div >
    
    <Modal  open={open} onClose={onCloseModal} center>
      <h2>Редагування принтера {item?.model} - Інв№ {item?.inv_number}</h2>

<div className='edit_pinter'>
   
    <label>Принтер</label>
<select disabled defaultValue={editPrinterId} value={editPrinterId} onChange={e => setEditPrinterId(e.target.value)}>
    {startData && startData?.printers?.filter(val => val?.id == Number(item?.printer_id)).map((val,idx) =>{
        return <option key={idx} value={val?.id === item?.printer_id}>
{item?.model}
        </option>
    })}
</select>

<label>Локація </label>

   
   <select defaultValue={editLocation} value={editLocation} onChange={(e)=>setEditLocation(e.target.value)} >

   {startData && startData?.location?.map((val,idx) =>{
        return <option key={idx} value={val?.id}>
{val?.location}
        </option>
    })}
   </select>
   
    <button onClick={updatePrinterLocation} className="">Оновити</button>
</div>
    </Modal>
  </div>
  )
}

export default PrinterLocationEditModal