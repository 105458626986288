import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsAuth } from "../../redux/slices/auth";
import { useEffect, useState } from "react";
import HeaderAvatar from "./header_avatar/HeaderAvatar";
import Button from "../button/Button";
import HeaderNav from "./header_nav/HeaderNav";
import HeaderLogo from "./header_logo/HeaderLogo";
import HeaderTime from "./header_time/HeaderTime";
import HeaderNotifications from "./header_push/HeaderNotifications";
import HeaderBurger from "./header_burger/HeaderBurger";
import snow from "../../assets/snow.png";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { BsChat } from "react-icons/bs";
import ReactModal from "../modal/ReactModal";
import SnowFallButton from "./snowfall-button/SnowFallButton";
import RadioPlayer from "../radio-player/RadioPlayer";
const Header = ({snowShow,  setMapControlOpen, mapControlOpen }) => {
  const isAuth = useSelector(selectIsAuth);
  const userData = useSelector((state) => state.auth.data);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openBurger, setOpenBurger] = useState(false);

  const location = useLocation();

  const onClickLogout = () => {
    if (window.confirm("Ви впенені що хочете вийти?")) {
      window.localStorage.removeItem("token");
      dispatch(logout());
      return navigate("/login");
    }
  };


  return (
    <header
      style={{ display: mapControlOpen ? "none" : "flex" }}
      className="header"
    >
      <div
        className="header__wrapper container"
        style={{ position: "relative" }}
      >
        <HeaderLogo />
        {token ? <HeaderTime /> : null}

        {token ? (
          <HeaderNav openBurger={openBurger} setOpenBurger={setOpenBurger} />
        ) : null}
        {token ? <HeaderNotifications /> : null}
        {token ? <ReactModal /> : null}
      
        {token ? <HeaderAvatar /> : null}
        {token ? (
          <HeaderBurger openBurger={openBurger} setOpenBurger={setOpenBurger} />
        ) : null}
        {token && location.pathname === "/map-page" ? (
          <button onClick={() => setMapControlOpen(true)} className="normal">
            Відкрити керування картою
          </button>
        ) : null}
        {/* {token && <RadioPlayer/>} */}
      </div>
    </header>
  );
};

export default Header;
