import React, { useState } from "react";
import axios from "axios";
import instance from "../../utils/axios";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");

  // Handle file change for XLS/XLSX
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  // Handle file name input change
  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  // Handle XLS/XLSX file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    if (!fileName.trim()) {
      alert("Please enter a custom file name.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await instance.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-filename": encodeURIComponent(fileName),
        },
      });

      setFileUrl(response.data.fileUrl); // Save the uploaded file URL
   
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading the file:", error);
      alert("Error uploading the file.");
    }
  };

  // Handle photo upload
  const handlePhotoUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a photo to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await instance.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

   
      alert("Photo uploaded successfully!");

      
      setPhotoUrl(response.data.fileUrl)
    } catch (error) {
      console.error("Error uploading the photo:", error);
      alert("Error uploading the photo.");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      {/* File upload for XLS/XLSX */}
      <div>
        <h3>Завантаження XLS/XLSX File</h3>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
        <input
          type="text"
          placeholder="Enter custom file name"
          value={fileName}
          onChange={handleFileNameChange}
          style={{ margin: "10px 0", display: "block" }}
        />
        <button onClick={handleUpload}>Завантажити XLS File</button>
      </div>

      {fileUrl && (
        <div style={{ marginTop: "20px" }}>
          <h4>Успішно завантажено</h4>
        </div>
      )}

      {/* Photo upload */}
      <div style={{ marginTop: "40px" }}>
        <h3>Завантаження фото</h3>
        <form onSubmit={handlePhotoUpload}>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <button type="submit" style={{ marginTop: "10px" }}>
            Завантажити фото
          </button>
        </form>
      </div>
      {photoUrl && (
        <div style={{ marginTop: "20px" }}>
          <h4>Успішно завантажено</h4>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
