import instance from "../../../utils/axios";

const DeleteTableButton = ({ tableName,setOpen }) => {

  
    const handleDelete = async () => {
      try {
        const response = await instance.get(`/drop-table/${tableName}`);
        if (response.status === 200) {
          alert("Таблицю успішно видалено!");
          setOpen(false)
        }
      } catch (error) {
        console.error("Помилка при видаленні таблиці:", error);
        alert("Не вдалося видалити таблицю.");
      }
    };
  
    return (
      <button style={{ width: "100px",backgroundColor:"red" }} onClick={handleDelete}>
        Видалити таблицю
      </button>
    );
  };
  
  export default DeleteTableButton;