import React, { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import snow from "../../../assets/snow.png";
const SnowFallButton = () => {
  const [snowActive, setSnowActive] = useState(false);
  const snowShow = window.localStorage.getItem("snow");
  const token = localStorage.getItem("token");

  
      const snowAppear = () => {
        if (snowShow === "yes") {
          setSnowActive(false);
          window.localStorage.removeItem("snow");
          window.location.reload();
        } else {
          setSnowActive(true);
          window.localStorage.setItem("snow", "yes");
          window.location.reload();
        }
      };


      useEffect(() => {}, [snowShow,snowActive]);
  return (
 <>
 
 {token ? (
        <div
          data-tooltip-id="my-tooltip"
          data-tooltip-content={`Включити снігопад`}
          onClick={snowAppear}
     className="snowfall__button"
        >
          <img
            src={snow}
            alt="snow"
            style={{
              width: "40px",
              position: "",
              right: "0px",
              top: "0px",
            }}
          />
          <Tooltip id="my-tooltip" />
        </div>
      ) : null}
 </>
  )
}

export default SnowFallButton