import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Workers from "./pages/Workers/Workers";
import Worker from "./pages/Worker/Worker";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Login from "./pages/Login/Login";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthMe } from "./redux/slices/auth";
import Transportation from "./pages/Transportation/Transportation";
import Carriers from "./pages/Carriers/Carriers";
import LogisticWork from "./pages/LogisticWork/LogisticWork";
import { addReduxZap, changeKilAmRedux, changeTzTypeRedux, changeZapCinaRedux, changeZapZbirRedux } from "./redux/slices/zap";
import ZapEditForm from "./components/zap/ZapEditForm";
import socket from "./utils/socket";
import CompanyFiles from "./pages/CompanyFiles/CompanyFiles";
import ClosedCargos from "./pages/ClosedCargos/ClosedCargos";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  fromAdminToUser,
  notifyNewZap,
  notifyRefreshZap,
  textToAllUsers,
} from "./utils/toasts";
import { beepSend, directorSound, msgToAllUsers } from "./helpers/audio";
import NotificationPanel from "./components/notification_panel/NotificationPanel";
import { addCommentRedux } from "./redux/slices/comments";
import MessageFromAdmin from "./components/messages/MessageFromAdmin";
import ZapReminder from "./components/messages/ZapReminder";
import { TbBrandTelegram } from "react-icons/tb";
import Carrier from "./pages/Carriers/Carrier";
import ZapDeleteForm from "./components/zap/ZapDeleteForm";
import NotificationMail from "./components/notification_panel/NotificationMail";
import { SiGooglemeet } from "react-icons/si";
import GoogleMeetItem from "./components/google_meet/GoogleMeetItem";
import { changeGoogleMeetOpen } from "./redux/slices/edit";
import { addGoogleMeetEvent } from "./redux/slices/events";
import ZapZakrForm from "./components/zap/ZapZakrForm";
import PrintersMain from "./pages/Printers/PrintersMain";
import ManagersFeedBack from "./components/modals/feedback/ManagersFeedBack";
import Feedback from "./pages/Feedback/Feedback";
import ArchiveLogisticWork from "./pages/ArchiveLogisticWork/ArchiveLogisticWork";
import Snowfall from "react-snowfall";
import PostService from "./pages/PostService/PostService";
import MapPage from "./pages/MapPage/MapPage";
import MapWrapper from "./pages/MapPage/MapWrapper";
import WebSiteStatistic from "./pages/AdminPanel/AdminWebSite/WebSiteStatistic";
import AdminWebZap from "./pages/AdminPanel/AdminWebZap/AdminWebZap";
import Tenders from "./pages/Tenders/Tenders";
import EmailsSend from "./pages/EmailsSend/EmailsSend";
import useGetUserGreetings from "./hooks/useGetUserGreetings";
import GreetingModal from "./components/greetings-list/GreetingModal";
function App() {
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  const userData = useSelector((state) => state.auth.data);
  const zapEditStatus = useSelector((state) => state.edit.zapEdit);
  const zapDeleteStatus = useSelector((state) => state.edit.zapDeleteStatus);
  const zapZakrStatus = useSelector((state) => state.edit.zapZakrStatus);
  const navigate = useNavigate();
  const eventsOpen = useSelector((state) => state.edit.eventsOpen);
  const googleMeetOpen = useSelector((state) => state.edit.googleMeetOpen);
  const mailOpen = useSelector((state) => state.edit.mailOpen);
  const location = useLocation();
  const googleMeet = window.localStorage.getItem("googleMeet");
  const events = useSelector((state) => state.events.events.items);
  const [areActiveToasts, setAreActiveToasts] = useState(false);
  const [mapControlOpen, setMapControlOpen] = useState(false);
const userGreetings = useGetUserGreetings();


  const [openHelp,setOpenHelp] = useState(false)
  const dismissAllToasts = () => {
    // Закриття всіх активних toasts
    toast.dismiss();
    // Оновлення стану
    setAreActiveToasts(false);
  };
  useEffect(() => {
    token && dispatch(fetchAuthMe());
  }, [dispatch]);
  useEffect(() => {
   if (token) {
    dispatch(fetchAuthMe());
   }else {
    window.localStorage.clear()
   }
  }, [dispatch]);

  useEffect(() => {
    socket.on("showStartGoogleMeet", (data) => {
      dispatch(changeGoogleMeetOpen());
      dispatch(addGoogleMeetEvent(data));
    });
  }, [socket]);

  const areToastsActive = () => {
    // Перевірка чи є активні toasts
    // console.log(toast.isActive());
    return toast.isActive();
  };
  useEffect(() => {
    areToastsActive();
  }, [socket, ToastContainer]);

  useEffect(() => {
    socket.on("showStartGoogleMeetWithTime", (data) => {
      dispatch(changeGoogleMeetOpen());
      // dispatch(addGoogleMeetEvent(data))
    });
  }, [socket]);

  useEffect(() => {}, [googleMeet]);
  useEffect(() => {
    if (userData) {
      socket.emit("newUser", userData);
    }
  }, [userData]);
  useEffect(() => {
    if (userData) {
      socket.on("refreshMsg", (data) => {
        if (data) {
          notifyRefreshZap(data);
          setAreActiveToasts(true);
          // beepSend();
        }
      });
    }
  }, [userData]);
  useEffect(() => {
    socket.on("windowReloadAllUsers", (data) => {
      window.location.reload();
    });
  }, [socket]);
  useEffect(() => {
    socket.on("showTextToAllUsers", (data) => {
      textToAllUsers(data.user, data.textToAllUsers);
      setAreActiveToasts(true);
      msgToAllUsers();
    });
  }, [socket]);
  useEffect(() => {
    socket.on("show_msg_from_admin", (data) => {
      if (userData?.KOD === data.kod) {
        fromAdminToUser(data.user, data.message);
        setAreActiveToasts(true);
        directorSound();
      }
    });
  }, [socket, userData]);
  useEffect(() => {
    if (token) {
      socket.on("showNewComment", (data) => {
        dispatch(
          addCommentRedux({
            KOD_OS: data.pKodAuthor,
            KOD_ZAP: data.pKodZap,
            PRIM: data.pComment,
            PIP: data.PIP,
            DAT: Date.now(),
            KOD: data.pKodComment,
          })
        );
      });
    }
  }, []);
  useEffect(() => {
    if (token) {
      socket.on("showEditKilAm", (data) => {
      
        dispatch(
          changeKilAmRedux(data)
        );
      });
    }
  }, []);
  useEffect(() => {
    if (token) {
      socket.on("showEditTzType", (data) => {
     
        dispatch(
          changeTzTypeRedux(data)
        );
      });
    }
  }, []);
  useEffect(() => {
    if (token) {
      socket.on("showZapCina", (data) => {
        
        dispatch(
          changeZapCinaRedux(data)
        );
      });
    }
  }, []);
  useEffect(() => {
    if (token) {
      socket.on("showZapZbir", (data) => {
       
        dispatch(
          changeZapZbirRedux(data)
        );
      });
    }
  }, []);

  useEffect(() => {
    const date = new Date();
    date.toISOString();

    if (token) {
      socket.on("showNewZap", (data) => {

        
        dispatch(
          addReduxZap({
            DAT: date,
            DATUPDATE: date,
            KOD_GROUP: data.pKodGroup,
            KOD_OS: data.pKodAuthor,
            ZAV: data.pZav,
            ROZV: data.pRozv,
            ZAPTEXT: data.pZapText,
            KOD: data.ZAP_KOD,
            PIP: data.PIP,
            COUNTCOMM: 0,
            COUNTNEWCOMM: 0,
            ISNEW: 1,
            ZAPCINA: data.pZapCina,
            ZAM: data.ZAM_NAME,
            PUNKTZ: data.pPunktZ,
            PUNKTR: data.pPunktR,
            KILAM: data.pKilAm,
            KILAMACT: data.pKilAm,
            ZBIR: data.pZbir,
            TZTYPE:data.tzName
          })
        );
        // notifyNewZap(userData, data);
        // setAreActiveToasts(true);
        beepSend();
      });
    }
  }, [socket]);

  useEffect(() => {
    socket.on("logoutAllUsers", (data) => {
      window.localStorage.removeItem("token");
      navigate("/");
    });
  }, [socket, token]);
  const snowShow = window.localStorage.getItem("snow");
  useEffect(() => {}, [zapDeleteStatus]);
  useEffect(() => {}, [events]);
  useEffect(() => {}, [snowShow]);

  return (
    <div className="main__app">
      {/* {location.pathname === '/map-page' ? null : <Header snowShow={snowShow} />} */}
      <Header setOpenHelp={setOpenHelp} setMapControlOpen={setMapControlOpen} mapControlOpen={mapControlOpen} snowShow={snowShow} />
      <div className="main__content">
        {snowShow === "yes" ? (
          <Snowfall
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
            }}
            snowflakeCount={200}
          />
        ) : null}
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/workers" element={<Workers />} />
            <Route path={`/workers/:id`} element={<Worker />} />{" "}
            <Route path={`/transportation`} element={<Transportation />} />
            <Route path={`/carriers`} element={<Carriers />} />
            <Route path={`/carriers/:id`} element={<Carrier />} />
            <Route path={`/logistic-work`} element={<LogisticWork />} />
            <Route path={`/tenders`} element={<Tenders />} />
            <Route
              path={`/archive-logistic-work`}
              element={<ArchiveLogisticWork />}
            />
            <Route path={`/ict-files`} element={<CompanyFiles />} />
            <Route path={`/statistic`} element={<ClosedCargos />} />
            <Route path={`/feedback`} element={<Feedback />} />
            <Route path={`/post-service`} element={<PostService />} />
            <Route path={`/web-site-statistic`} element={<WebSiteStatistic />} />
            <Route path={`/web-site-zap`} element={<AdminWebZap />} />
            <Route path={`/emails-send`} element={<EmailsSend />} />
            <Route
              path={`/map-page`}
              element={<MapPage mapControlOpen={mapControlOpen} setMapControlOpen={setMapControlOpen} />}
            />
            {/* <Route path={`/map-page`} element={<MapWrapper />} /> */}
            {userData?.KOD === 24011 || userData?.KOD === 38231 ? (
              <Route path={`/printers`} element={<PrintersMain />} />
            ) : null}
            {/* <Route path={`/meeting`} element={<MeetingPage />} /> */}
            {userData?.ISDIR === 1 ||
            userData?.KOD === 38231 ||
            userData?.KOD === 24011 ||
            userData?.KOD === 4611 ||
            userData?.KOD === 3711 ||
            userData?.KOD === 2811 ||
            userData?.KOD === 6411 ||
            userData?.KOD === 7631 ||
            userData?.KOD === 26811 ||
            userData?.KOD === 19011 ? (
              <Route path={`/admin`} element={<AdminPanel />} />
            ) : (
              <Route path={`/logistic-work`} element={<LogisticWork />} />
            )}
          </Route>
        </Routes>
        {/* {zapEditStatus ? <ZapEditForm /> : null}
        {zapDeleteStatus ? <ZapDeleteForm /> : null}
        {zapZakrStatus ? <ZapZakrForm /> : null} */}
        {eventsOpen && <NotificationPanel />}
        {mailOpen && <NotificationMail />}
        {/* {googleMeetOpen ? <GoogleMeetItem /> : null} */}

        {userData?.KOD === 24011
          ? null
          : <ToastContainer />}
        <MessageFromAdmin />
        <ZapReminder />
        <ManagersFeedBack />
        {location.pathname === "/logistic-work" && areActiveToasts && (
          <button className="normal toasts__close" onClick={dismissAllToasts}>
            Закрити всі сповіщення
          </button>
        )}
        {location.pathname === "/logistic-work" && (
          <div title="Технічна підтримка" className="telegram__chat">
            <a target="_blank" href="https://t.me/I_Dont_Have_A_Phone_Number">
              <TbBrandTelegram />
            </a>
          </div>
        )}
      </div>
   <GreetingModal userId={userData?.KOD}/>
    </div>
  );
}

export default App;
