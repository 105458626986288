import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "../../../utils/axios";
const CartridgesEditModal = ({ open, setOpen, item, refreshFunc }) => {


  const [editModel, setEditModel] = useState(item?.cartridge_model);
  const [editInvNumber, setEditInvNumber] = useState(item?.serial_number);

  const updatePrinter = async () => {
    const obj = {
      id: item.id,
      cartridge_model: editModel,
      serial_number: editInvNumber,
    };
    try {
      const data = await axios.post("/printers/update-cartridge", obj);
    
      if (data.status === 200) {
        setOpen(false);
        refreshFunc();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setEditInvNumber(item?.serial_number);
    setEditModel(item?.cartridge_model);
  }, [item]);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>
          Редагування картриджа Модель: {item?.cartridge_model} - Сер.номер{" "}
          {item?.serial_number}
        </h2>

        <div className="edit_pinter">
          <label>Модель: </label>
          <input
            type="text"
            defaultValue={editModel}
            value={editModel}
            onChange={(e) => setEditModel(e.target.value)}
          />
          <label>Серійний номер: </label>
          <input
            type="text"
            value={editInvNumber}
            onChange={(e) => setEditInvNumber(e.target.value)}
          />
          <button onClick={updatePrinter} className="">
            Оновити
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CartridgesEditModal;
