import { useState, useEffect } from "react";
import axios from "axios";
import instance from "../utils/axios";

/**
 * Custom hook to fetch greetings from the server for a specific user.
 * @param {number} userId - The ID of the user for whom to fetch greetings.
 * @returns {Object} - Contains greetings, loading state, and error.
 */
const useGetUserGreetings = (userId, refreshFunc) => {
  const [greetings, setGreetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) {
      setError("User ID is required");
      setLoading(false);
      return;
    }

    const fetchGreetings = async () => {
      try {
        const response = await instance.post("/greetings/all", {
          user_id: userId,
        });
        console.log("GREEETINGS", response);

        setGreetings(response.data);
      } catch (err) {
        setError(err.response?.data?.error || "Failed to fetch greetings");
      } finally {
        setLoading(false);
      }
    };

    fetchGreetings();

    if (refreshFunc) {
      setTimeout(() => {
        fetchGreetings();
      }, 5000);
    }
  }, [userId, refreshFunc]);

  return { greetings, loading, error };
};

export default useGetUserGreetings;
