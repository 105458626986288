import { useDispatch } from "react-redux";
import "./Printers.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useSelector } from "react-redux";

import { useEffect } from "react";
import axios from "../../utils/axios";
import { useState } from "react";
import PrintersEditModal from "./modals/PrintersEditModal";
import CartridgesEditModal from "./modals/CartridgesEditModal";
import CartridgeChangeModal from "./modals/CartridgeChangeModal";
import PrinterLocationEditModal from "./modals/PrinterLocationEditModal";


function formatLocalDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
const PrintersMain = () => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.data)
  const [startData, setStartData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [openCartridgeChange, setOpenCartridgeChange] = useState(false);

  const [creationAnswerPrinters, setCreationAnswerPrinters] = useState("");
  const [creationAnswerCartridges, setCreationAnswerCartridges] = useState("");

  const [nPrinterModel, setNPrinterModel] = useState("");
  const [nPrinterInv, setNPrinterInv] = useState("");

  const [nCartridgeModel, setNCartridgeModel] = useState("");
  const [nCartridgeSerial, setNCartridgeSerial] = useState("");


  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [cartChangeData, setCartChangeData] = useState([]);

  const [printersModalOpen,setPrintersModalOpen] = useState(false)
  const [printerEditData,setPrinterEditData] = useState(null)

  
  const [cartridgeModalOpen,setCartridgeModalOpen] = useState(false)
  const [cartridgeEditData,setCartridgeEditData] = useState(null)

  const [cartridgeChangeModalOpen,setCartridgeChangeModalOpen] = useState(false)
  const [cartridgeChangeEditData,setCartridgeChangeEditData] = useState(null)

  const [printerLocationChangeModalOpen,setPrinterLocationChangeModalOpen] = useState(false)
  const [printerLocationChagneEditData,setPrinterLocationChangeEditData] = useState(null)

  const getAllCartChangeData = async () => {
    try {
      const data = await axios.get("/printers/all-cartridge-changes");
      if (data.status === 200) {
        setCartChangeData(data.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (!selectedPrinter || !selectedLocation) {
      alert("Будь ласка, виберіть принтер і місцезнаходження.");
      return;
    }

    const obj = {
      printer_id: selectedPrinter,
      location_id: selectedLocation,
    };


    try {
      const data = await axios.post("/printers/printer-location",obj);

if (data.status === 200) {
  getAllDataForChange()
}

    } catch (error) {
      console.log('ERRROR',error);
      
      console.error("Помилка запиту:", error);
      alert("Неможливо відправити дані.Схоже що принтер вже привязаний до місця");
    }
  };
  // Стан для форми заміни картриджів
  const [formDataCartridgeChange, setFormDataCartridgeChange] = useState({
    printer_id: "",
    cartridge_id: "",
    counter: "",
    cart_change_date: "",
    comment: "",
    price:"",
  });
  // Функція для оновлення стану
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataCartridgeChange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Функція для відправлення даних
  const handleSubmitCartridgeChange = async () => {
  try {
 
    const res = await createCartridgeChange(formDataCartridgeChange);
    // Додайте тут логіку для збереження даних (API, локальний стан тощо)
  
    if (res === 400) {
      alert("Щось пішло не так.Перевірте усі поля");
    } 
    if (res.status && res.status === 200) {
     
      alert("Записано");
      setOpenCartridgeChange(false);
      getAllCartChangeData()
    }
  
  } catch (error) {
    console.log(error);
    
  }
  };
  const getAllDataForChange = async () => {
    try {
      const data = await axios.get("/printers/all-data");
  
      setStartData(data.data);
      setCartChangeData(data.data.cart_change)
    } catch (error) {
      console.log(error);
    }
  };

  const RefreshButton = () => {
    return (
      <button
        style={{ width: "30%" }}
        onClick={getAllDataForChange}
        className="normal"
      >
        Оновити
      </button>
    );
  };

  const createPrinter = async () => {
    try {
      if (nPrinterInv && nPrinterModel) {
        const data = await axios.post("/printers/new-printer", {
          model: nPrinterModel,
          inv_number: nPrinterInv,
        });

        if (data.status === 200) {
          setCreationAnswerPrinters(`Створено ${data.data.rows[0].inv_number}`);
    
          startData.printers.push(data.data.rows[0]);

          setTimeout(() => {
            setCreationAnswerPrinters("");
          }, 2000);
        }
      } else {
        alert("Заповніть усі поля");
      }
    } catch (error) {
      console.log(error);
      if (error) {
        setCreationAnswerPrinters("Вже існує такий інвентарний номер ТМЦ");
        setTimeout(() => {
          setCreationAnswerPrinters("");
        }, 2000);
      }
    }
  };
  const createCartridges = async () => {


    try {
      if (nCartridgeSerial && nCartridgeModel) {
        const data = await axios.post("/printers/new-cartridge", {
          serial_number: nCartridgeSerial,
          cartridge_model: nCartridgeModel,
        });
       

        if (data.status === 200) {
          setCreationAnswerCartridges(
            `Створено ${data.data.rows[0].cartridge_model}`
          );
          startData.cartridges.push(data.data.rows[0]);

          setTimeout(() => {
            setCreationAnswerCartridges("");
          }, 2000);
        }
      } else {
        alert("Заповніть усі поля");
      }
    } catch (error) {
      console.log(error);
      if (error) {
        setCreationAnswerCartridges("Вже існує такий інвентарний номер ТМЦ");
        setTimeout(() => {
          setCreationAnswerCartridges("");
        }, 2000);
      }
    }
  };
  const deletePrinter = async (item) => {
    const obj = {
      id: item.id,
    };
    try {
      const data = await axios.post("/delete-printer", obj);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteCartridge = async (item) => {
    const obj = {
      id: item.id,
    };
    try {
      const data = await axios.post("/delete-cartridge", obj);
    } catch (error) {
      console.log(error);
    }
  };
  const updateCartridge = async (item) => {
    const obj = {
      id: item.id,
    };
    try {
      const data = await axios.post("/update-cartridge", obj);
    } catch (error) {
      console.log(error);
    }
  };
  const updatePrinter = async (item) => {
    const obj = {
      id: item.id,
    };
    try {
      const data = await axios.post("/update-printer", obj);
    } catch (error) {
      console.log(error);
    }
  };
  const createCartridgeChange = async (obj) => {
    try {
      const data = await axios.post("/printers/cartridge-change", obj);
    

      return data;
    } catch (error) {
      console.log(error);
      if (error) {
        return error.response.status;
      }
    }
  };


useEffect(()=>{
  getAllDataForChange()
},[])

const editPrinter = (item)=>{
  setPrintersModalOpen(true)
  setPrinterEditData(item)
}
const editCartridge = (item)=>{
  setCartridgeModalOpen(true)
  setCartridgeEditData(item)
}
const editCartridgeChagne = (item)=>{
  setCartridgeChangeModalOpen(true)
  setCartridgeChangeEditData(item)
}
const editPrinterLocation = (item)=>{
  setPrinterLocationChangeModalOpen(true)
  setPrinterLocationChangeEditData(item)
}
console.log('START DATA',startData);

  return (
    <div className="printers container">
      <Tabs>
        <TabList>
          <Tab>Принтери</Tab>
          <Tab>Картриджі</Tab>
          <Tab>Локації</Tab>
          <Tab onClick={getAllCartChangeData}>Заміна картриджів</Tab>
    
        </TabList>
        {/* Принтери */}
        <TabPanel>
          <div
            className="printers"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="add_printer">
              {/* const [nPrinterModel,setNPrinterModel] = useState('')
          const [nPrinterInv,setNPrinterInv] = useState('') */}
              <input
                type="text"
                value={nPrinterModel}
                placeholder="Модель принтера"
                onChange={(e) => setNPrinterModel(e.target.value)}
              />
              <input
                type="text"
                value={nPrinterInv}
                placeholder="Інвентарний номер принтера"
                onChange={(e) => setNPrinterInv(e.target.value)}
              />
              <button className="normal" onClick={createPrinter}>
                Додати модель принтера
              </button>
             
              {creationAnswerPrinters && (
                <span style={{ color: "red" }}>{creationAnswerPrinters}</span>
              )}
            </div>

            <RefreshButton />
            <div className="printers__list">
              {startData?.printers &&
                startData?.printers.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="printer__item"
                      style={{
                        display: "flex",
                        gap: "100px",
                        marginTop: "20px",
                      }}
                    >
                      <span style={{ width: "50%" }} className="printer_model">
                        Модель: {item.model}
                      </span>
                      <span style={{ width: "50%" }} className="printer_inv">
                        Інвентарний номер: {item.inv_number}
                      </span>
                      <button onClick={()=>editPrinter(item)} className="normal edit">Редагувати</button>
                      
                    </div>
                  );
                })}
            </div>
          </div>
        </TabPanel>
        {/* Картриджі */}
        <TabPanel>
          <div className="cartridges">
            <div className="add_printer">
              {/* const [nCartridgeModel,setNCartridgeModel] = useState('')
          const [nCartridgeSerial,setNCartridgeSerial] = useState('') */}
             
             <input
                type="text"
                placeholder="Модель картриджа"
                value={nCartridgeModel}
                onChange={(e) => setNCartridgeModel(e.target.value)}
              />
              <input
                type="text"
                placeholder="Серійний номер картриджа"
                value={nCartridgeSerial}
                onChange={(e) => setNCartridgeSerial(e.target.value)}
              />
      
              <button className="normal" onClick={createCartridges}>
                Додати модель картриджа
              </button>
              {creationAnswerCartridges && (
                <span style={{ color: "red" }}>{creationAnswerCartridges}</span>
              )}
            </div>
            <RefreshButton />
            <div className="printers__list">
              {startData?.cartridges &&
                startData?.cartridges.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="printer__item"
                      style={{
                        display: "flex",
                        gap: "100px",
                        marginTop: "20px",
                      }}
                    >
                      <span style={{ width: "50%" }} className="printer_model">
                        Серійний номер: {item.serial_number}
                      </span>
                      <span style={{ width: "50%" }} className="printer_inv">
                        Модель: {item.cartridge_model}
                      </span>
                      <button onClick={()=>editCartridge(item)} className="normal edit">Редагувати</button>

                    </div>
                  );
                })}
            </div>
          </div>
        </TabPanel>
        {/* Локації */}
        <TabPanel>
      {/* Вибір принтера */}
      <select
        onChange={(e) => setSelectedPrinter(e.target.value)}
        defaultValue=""
      >
        <option value="" disabled>
          Виберіть принтер
        </option>
        {startData?.printers
          ?.filter((item) => item.location_id == null)
          .map((item) => (
            <option key={item.id} value={item.id}>
              {item.model} - {item.inv_number}
            </option>
          ))}
      </select>

      {/* Вибір місцезнаходження */}
      <select
        onChange={(e) => setSelectedLocation(e.target.value)}
        defaultValue=""
      >
        <option value="" disabled>
          Виберіть місцезнаходження
        </option>
        {startData?.location?.map((item, idx) => (
          <option key={item.id} value={item.id}>
            {item.location}
          </option>
        ))}
      </select>


      {/* Кнопка для надсилання */}
      <button className="normal" onClick={handleSubmit}>Зберегти</button>

      <div className="printers_location">
        {startData?.printer_location?.map((item,idx)=>{
          return <div style={{display:"flex",gap:"10px",border:"1px solid grey",marginTop:"20px",justifyContent:"space-between"}} key={idx} className="printer_location_item">
      <div className="info" style={{display:"flex",gap:"10px"}}>
      <div>{item.location}</div>
            <div>Модель: {item.model}</div>
            <div>Інв№: {item.inv_number}</div>
      </div>
            <button onClick={e => editPrinterLocation(item)} className="normal edit">Редагувати</button>
          
          </div>
        })}
      </div>
    </TabPanel>
        {/* Заміна картриджів */}
        <TabPanel>
          <div className="cartridge_change-wrapper">
            <button onClick={() => setOpenCartridgeChange((val) => !val)}>
              {openCartridgeChange ? "Закрити внесення" : "Внести заміну"}
            </button>
            <button
              style={{ marginLeft: "10px" }}
              className="normal"
              onClick={getAllDataForChange}
            >
              Оновити інформацію
            </button>
            {openCartridgeChange && (
              <div className="change">
                <div
                  className="cartridges_change"
                  style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                >
                  {/* Оберіть принтер */}
                  <div
                    className="item_choose_form"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Оберіть принтер</label>
                    <select
                      name="printer_id"
                    
                      value={formDataCartridgeChange.printer_id}
                      onChange={handleChange}
                    >
                      <option value="">Оберіть</option>
                      {startData?.printers &&
                        startData?.printers.map((item) => (
                          <option key={item.id} value={item.id}>
                            Модель: {item.model} - ІНВ№: {item.inv_number}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/* Оберіть картридж */}
                  <div
                    className="item_choose_form"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Оберіть картридж</label>
                    <select
                      name="cartridge_id"
                      value={formDataCartridgeChange.cartridge_id}
                      onChange={handleChange}
                    >
                      <option value="">Оберіть</option>
                      {startData?.cartridges &&
                        startData?.cartridges.map((item) => (
                          <option key={item.id} value={item.id}>
                            Модель: {item.cartridge_model} - Серійний номер:{" "}
                            {item.serial_number}
                          </option>
                        ))}
                    </select>
                  </div>

                  {/* К-сть копій */}
                  <div
                    className="item_choose_form"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>К-сть копій</label>
                    <input
                      type="number"
                      name="counter"
                      value={formDataCartridgeChange.counter}
                      onChange={handleChange}
                    />
                  </div>

                  {/* Дата заміни */}
                  <div
                    className="item_choose_form"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Дата заміни</label>
                    <input
                      type="date"
                      name="cart_change_date"
                      value={formDataCartridgeChange.cart_change_date}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Ціна заміни */}
                  <div
                    className="item_choose_form"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Ціна</label>
                    <input
                      type="number"
                      name="price"
                      value={formDataCartridgeChange.price}
                      onChange={handleChange}
                    />
                  </div>

                  {/* Коментар */}
                  <div
                    className="item_choose_form"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Коментар</label>
                    <textarea
                      name="comment"
                      cols={20}
                      value={formDataCartridgeChange.comment}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button
                  onClick={handleSubmitCartridgeChange}
                  className="normal"
                >
                  Додати
                </button>
              </div>
            )}
            {cartChangeData &&
              cartChangeData.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="item"
                    style={{ marginTop: "20px", display: "flex", gap: "10px",flexWrap:"wrap",border:"1px solid grey",padding:"6px" ,borderRadius:"10px"}}
                  >
                    <select  disabled defaultValue={item.printer_id}>
                      {startData?.printers?.map((val, id) => {
                console.log('VAL',val);
                
                const filterLocation = startData?.location?.filter((loc) =>{
                  return loc.id == val.location_id
                })

                console.log('FILTER LOCATION',filterLocation);
                

                        return (
                          <option key={id} value={val.id}>
                            Модель : {val.model} - Інв№: {val.inv_number} - {filterLocation[0]?.location ? filterLocation[0]?.location : "Місцезнаходження відсутнє"}
                          </option>
                        );
                      })}
                    </select>
                    <select  disabled defaultValue={item.cartridge_id}>
                      {startData?.cartridges?.map((val, id) => {
                      

                        return (
                          <option key={id} value={val.id}>
                            Модель : {val.cartridge_model} - Інв№: {val.serial_number}
                          </option>
                        );
                      })}
                    </select>

                    <input type="text" disabled value={item.counter} />
                    <input
                      type="date"
                      disabled
                      value={
                        item.cart_change_date
                          ? formatLocalDate(item.cart_change_date)
                          : ""
                      }
                    />
                    <input type="number" disabled value={item.price} />
                    <textarea disabled cols={30} rows={2} value={item.comment}/>
                    <button onClick={()=>editCartridgeChagne(item)} className="normal">Змінити</button>
                  </div>
                );
              })}
          </div>
        </TabPanel>
        {/* Інфо
        <TabPanel>
          <div className="printers_info">info</div>
        </TabPanel> */}
      </Tabs>

      <PrintersEditModal refreshFunc={getAllDataForChange} item={printerEditData}  open={printersModalOpen} setOpen={setPrintersModalOpen}/>
      <CartridgesEditModal refreshFunc={getAllDataForChange} item={cartridgeEditData}  open={cartridgeModalOpen} setOpen={setCartridgeModalOpen}/>
      <CartridgeChangeModal startData={startData} cartChangeData={cartChangeData} refreshFunc={getAllDataForChange} item={cartridgeChangeEditData}  open={cartridgeChangeModalOpen} setOpen={setCartridgeChangeModalOpen}/>
      <PrinterLocationEditModal startData={startData} cartChangeData={printerLocationChagneEditData} refreshFunc={getAllDataForChange} item={printerLocationChagneEditData}  open={printerLocationChangeModalOpen} setOpen={setPrinterLocationChangeModalOpen}/>
    </div>
  );
};

export default PrintersMain;
