import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import useGetUserGreetings from "../../hooks/useGetUserGreetings";
import instance from "../../utils/axios";
import { useSelector } from "react-redux";
import Snowfall from 'react-snowfall'
const GreetingModal = ({ userId }) => {
    const [refreshFunc,setRefreshFunc] = useState(false)
  const { greetings, loading, error } = useGetUserGreetings(userId,refreshFunc);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const userData = useSelector((state) => state.auth.data);
  const [greetingsShow,setGreetingsShow] = useState([])

  // Закриття модального вікна
  const onCloseModal = () => setOpen(false);

  // Перевірка, чи користувач переглянув привітання
  const checkUserViewedGreeting = async () => {
    try {
      const data  = await instance.post("/greetings/check-viewd", {
        user_id: userId,text_id:greetings[currentGreeting].id
      });
    
    
      setGreetingsShow(data)
      
    //   return data;
    } catch (error) {
      console.error("Помилка перевірки привітань:", error);
      return false;
    }
  };

  // Виконуємо перевірку та відкриваємо модальне вікно
  useEffect(() => {
    const initGreetings = async () => {
  
//   const data = await checkUserViewedGreeting()

      if ( greetings.length > 0) {
        setTimeout(() => {
          setOpen(true);
        }, 3000); // Затримка для показу
      }
    };

initGreetings()
  }, [greetings,greetingsShow, userId]);

  const currentGreeting = greetings[currentIndex];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % greetings.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? greetings.length - 1 : prevIndex - 1
    );
  };

  const handleClose = async () => {
    try {
     const data =  await instance.post("/greetings/mark-as-viewed", {
        user_id: userId,text_id:greetings[currentIndex].id
      });

  
      if (data.status === 201 || data.status === 200) {
        setRefreshFunc(true)
              setOpen(false);
        
      }

    } catch (error) {
      console.error("Помилка закриття привітання:", error);
    }
  };

  useEffect(() => {
    if (currentGreeting) {
    //   console.log("Поточне привітання:", currentGreeting);
    }
  }, [currentGreeting]);


 
  
if (!currentGreeting || currentGreeting === undefined) {
    return null
}
  return (
    <>
    
    <Snowfall  snowflakeCount={200}  color="lightblue"/>
 
    <Modal
    showCloseIcon={false}
    closeOnOverlayClick={false}
    open={open} onClose={onCloseModal} center>
      <div style={{ display: 'flex', flexDirection: 'column' }} className='info'>
     
        <p style={{wordBreak:"break-word",whiteSpace:"pre-wrap"}}>{currentGreeting?.text}</p>
        {currentGreeting?.img && (
          <img
            src={currentGreeting.img}
            alt="greetings"
            style={{ width: "100%", height: "auto" }}
          />
        )}
        <div className="modal-controls" style={{display:"flex",justifyContent:"space-between"}}>
          {greetings.length > 1 && (
            <button onClick={handlePrevious}>&lt; Назад</button>
          )}
          {greetings.length > 1 && (
            <button onClick={handleNext}>Далі &gt;</button>
          )}
        </div>
        <button style={{marginTop:"20px",color:"black"}} className="modal-close normal"  onClick={handleClose}>
          Закрити
        </button>
     

      </div>
    </Modal>

    </>
  );
};

export default GreetingModal;
