import React from "react";
import Modal from "react-responsive-modal";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdOutlineExitToApp } from "react-icons/md";
import DeleteTableButton from "../../../components/button/delete-table/DeleteTableButton";
const EmailSendDeleteModal = ({ open, setOpen, item }) => {
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);




return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <div
          style={{
            display: "flex",
            gap: "100px",
            flexDirection: "column",
            margin: "40px",
            padding: "40px",
            cursor:"context-menu"
          }}
          className="info"
        >


<DeleteTableButton tableName={item?.table_name} setOpen={setOpen}/>

          {/* <div style={{ display: 'flex', gap:"10px",backgroundColor:"red"}} className='info'>
            <span>Підтвердити видалення</span>
            <RiDeleteBin6Fill size={24} />
          </div> */}
          <div style={{ display: 'flex', gap:"10px",backgroundColor:"green"}} className='info'>
            <span>Відхилити видалення</span>
            <MdOutlineExitToApp size={24} onClick={onCloseModal} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmailSendDeleteModal;
