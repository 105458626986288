import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from '../../../utils/axios';

function formatLocalDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const CartridgeChangeModal = ({ open, setOpen, item, refreshFunc, cartChangeData, startData }) => {
  const [updatedCartChangeData, setUpdatedCartChangeData] = useState([]);

  const handleInputChange = (idx, key, value) => {
    setUpdatedCartChangeData(prevData =>
      prevData.map((entry, index) => (index === idx ? { ...entry, [key]: value } : entry))
    );
  };

  const updateCartridgeChange = async (updatedItem) => {
    const obj = {
      id: updatedItem.id,
      printer_id: updatedItem.printer_id,
      cartridge_id: updatedItem.cartridge_id,
      counter: updatedItem.counter,
      cart_change_date: updatedItem.cart_change_date,
      price: updatedItem.price,
      comment: updatedItem.comment,
    };
    try {
      const response = await axios.post('/printers/update-cartridge-change', obj);
      if (response.status === 200) {
        setOpen(false);
        refreshFunc();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (cartChangeData) {
      setUpdatedCartChangeData(cartChangeData.filter(val => val?.id === item?.id));
    }
  }, [cartChangeData, item]);

  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>Редагувати заміну картриджа {item?.model} - Інв№ {item?.inv_number}</h2>
        {updatedCartChangeData.map((data, idx) => (
          <div
            key={idx}
            className="item"
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              border: "1px solid grey",
              padding: "6px",
              borderRadius: "10px",
            }}
          >
            <select
              value={data.printer_id}
              onChange={(e) => handleInputChange(idx, 'printer_id', e.target.value)}
            >
              {startData?.printers?.map((val) => (
                <option key={val.id} value={val.id}>
                  Модель : {val.model} - Інв№: {val.inv_number}
                </option>
              ))}
            </select>

            <select
              value={data.cartridge_id}
              onChange={(e) => handleInputChange(idx, 'cartridge_id', e.target.value)}
            >
              {startData?.cartridges?.map((val) => (
                <option key={val.id} value={val.id}>
                  Модель : {val.cartridge_model} - Інв№: {val.serial_number}
                </option>
              ))}
            </select>

            <input
              type="text"
              value={data.counter}
              onChange={(e) => handleInputChange(idx, 'counter', e.target.value)}
            />

            <input
              type="date"
              value={data.cart_change_date ? formatLocalDate(data.cart_change_date) : ""}
              onChange={(e) => handleInputChange(idx, 'cart_change_date', e.target.value)}
            />

            <input
              type="number"
              value={data.price}
              onChange={(e) => handleInputChange(idx, 'price', e.target.value)}
            />

            <textarea
              cols={30}
              rows={2}
              value={data.comment}
              onChange={(e) => handleInputChange(idx, 'comment', e.target.value)}
            />

            <button onClick={() => updateCartridgeChange(data)}>
              Змінити
            </button>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default CartridgeChangeModal;
