import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from '../../../utils/axios'
const PrintersEditModal = ({open,setOpen,item,refreshFunc}) => {
    
const [editModel,setEditModel] = useState(item?.model)
const [editInvNumber,setEditInvNumber] = useState(item?.inv_number)

const updatePrinter = async ()=>{
    const obj = {
        id:item.id,
        model:editModel,
        inv_number:editInvNumber
    }
    try {
        const data = await axios.post('/printers/update-printer',obj);
    
        if (data.status === 200) {
            setOpen(false);
            refreshFunc();
        }
        
    } catch (error) {
        console.log(error);
        
    }
}
useEffect(()=>{
    setEditInvNumber(item?.inv_number)
    setEditModel(item?.model)
},[item])
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div >
    
    <Modal  open={open} onClose={onCloseModal} center>
      <h2>Редагування принтера {item?.model} - Інв№ {item?.inv_number}</h2>

<div className='edit_pinter'>
    <label>Модель: </label>
    <input type="text" defaultValue={editModel} value={editModel} onChange={(e)=>setEditModel(e.target.value)}  />
    <label>Інв.номер: </label>
    <input type="text" value={editInvNumber} onChange={(e)=>setEditInvNumber(e.target.value)}  />
    <button onClick={updatePrinter} className="">Оновити</button>
</div>
    </Modal>
  </div>
  )
}

export default PrintersEditModal